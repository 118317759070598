import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { axiosGet } from 'utils/api-utils';
import { authDomain } from 'utils/envConfig';
import { postOrderCleanup } from './CheckoutHelpers';
import logError from 'utils/errorHandler';
import { getLoginState, getCAPIUserInfo, getCustomer, getIsAuthenticationPending } from 'reduxState/user';

/* /affirm-success - affirm success! Perform post order cleanup for Affirm and then redirect to order conf */
const AffirmHandlingRoutes = ({ orderId }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginState);
  const isAuthenticationPending = useSelector(getIsAuthenticationPending);
  const capiData = useSelector(getCAPIUserInfo);
  const customer = useSelector(getCustomer);

  useEffect(() => {
    const postAffirmCleanup = async () => {
      try {
        const url = `/app/public/getOrder/${orderId}`;
        // Get order information
        const { data } = await axiosGet(url, { headers: { authDomain } });
        postOrderCleanup({ order: { ...data, eventPayMethod: 'AFFIRM' }, dispatch, isLoggedIn, capiData, customer });
      } catch (error) {
        logError(error);
      }
    };
    if (!isAuthenticationPending) {
      postAffirmCleanup();
    }
  }, [isAuthenticationPending]);

  return null;
};

AffirmHandlingRoutes.propTypes = {
  orderId: PropTypes.string,
};
export default AffirmHandlingRoutes;
