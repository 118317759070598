import logError from 'utils/errorHandler';

/**
 * Retries the given function until it succeeds given a number of retries and an interval between them. They are set
 * by default to retry 2 times with 1sec in between. Force refreshes window if all retries fail
 * @author Daniel Iñigo <danielinigobanos@gmail.com> (adapted)
 * @param {Function} fn - Returns a promise
 * @param {Number} retriesLeft - Number of retries. If -1 will keep retrying
 * @param {Number} interval - Millis between retries. If exponential set to true will be doubled each retry
 * @return {Promise<*>}
 */
const retry = async (fn, retriesLeft = 1, interval = 1000) => {
  try {
    const val = await fn();
    return val;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => setTimeout(r, interval));
      return retry(fn, retriesLeft - 1, interval);
    } else if (!window.location.href.includes('?forceReload=')) {
      // force refresh and invalidate cached resources
      if (window) {
        window.location = window.location + `?forceReload=${new Date().getTime()}`;
      }
    } else {
      logError(error);
    }
  }
};

export default retry;
