import styled from 'styled-components';

import breakpoints from 'theme/Media';

export const FeaturedSeedlings = styled.div.attrs({ className: 'FeaturedSeedlings' })`
  padding: 5rem 2rem;
  margin: 5rem 0;
  background: white;
`;

export const Instructions = styled.div.attrs({ className: 'Instructions' })`
  background: ${(props) => props.theme.farmProject.gray_2};
  margin: 5rem auto;
  padding: 2rem;
  @media ${breakpoints.medium_break} {
    padding: 5rem 0;
  }
`;

export const DiscoverMore = styled.div.attrs({ className: 'DiscoverMore' })`
  margin: 0 auto;
  margin-bottom: 5rem;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, minmax(15rem, 1fr));
  column-gap: 2rem;
  .Card .lg-text {
    margin-bottom: 0.8rem;
  }
`;

export const CarouselWrapper = styled.div.attrs({ className: 'CarouselWrapper' })`
  margin: 5rem auto;
  img {
    width: 100%;
  }
`;

export const RecipeDetailStyled = styled.div.attrs({ className: 'RecipeDetailStyled' })`
  .lg-recipe__inner {
    margin-top: 0;

    .lg-recipe__heading {
      width: 100%;
    }

    > .lg-text {
      width: 100%;
    }
  }
`;

export const RecipeBackButton = styled.div.attrs({ className: 'RecipeBackButton' })`
  max-width: 40rem;
  margin: auto;

  @media ${breakpoints.large_break} {
    max-width: 70rem;
  }
`;

export const RecipeInformation = styled.div.attrs({ className: 'RecipeInformation' })`
  padding: 1.25rem;
  max-width: 40rem;
  margin: auto;

  @media ${breakpoints.large_break} {
    max-width: 70rem;
  }
`;

export const FeaturedSeedlingsInner = styled.div.attrs({ className: 'FeaturedSeedlingsInner' })`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 70rem;
  margin: auto;
  width: 100%;

  .SwiperStyled {
    width: 100%;

    @media ${breakpoints.large_break} {
      max-width: 25rem;
    }

    .swiper-container {
      width: 100%;

      .swiper-wrapper {
        width: 100%;

        .swiper-slide {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .lg-callout {
    max-width: 50%;
  }
`;

export const InstructionsInner = styled.div.attrs({ className: 'InstructionsInner' })`
  max-width: 70rem;
  margin: auto;
  padding: 0 1.5rem;

  .lg-recipe__instructions {
    max-width: 40rem;
    margin: auto;

    h5 {
      font-weight: 500;
    }
  }
`;

export const DiscoverMoreSection = styled.div.attrs({ className: 'DiscoverMoreSection' })`
  .Card {
    .lg-media-wrapper {
      margin-bottom: 1rem;
    }

    .Title {
      margin-top: 0.5rem;
    }
  }

  .SwiperStyled {
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .Card {
            .lg-media-wrapper {
              margin-bottom: 1rem;
            }

            .Title {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
`;
