import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _intersectionBy from 'lodash/intersectionBy';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import _take from 'lodash/take';
import _difference from 'lodash/difference';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setIsLoading } from 'reduxState/appSettings';

import formatDate from 'utils/date-utils';
import { Link } from 'gatsby';

import Text from 'components/deprecated/elements/Text';
import Callout from 'components/deprecated/Callout';
import { Card, Media, Swiper } from 'components';
import WidthProvider from 'components/deprecated/WidthProvider';
import Head from 'components/Head';
import { Title } from 'elements';
import {
  CarouselWrapper,
  DiscoverMore,
  FeaturedSeedlings,
  Instructions,
  RecipeDetailStyled,
  RecipeBackButton,
  RecipeInformation,
  FeaturedSeedlingsInner,
  InstructionsInner,
  DiscoverMoreSection,
} from './RecipeDetail.styled';

import { colors, sizes, positions, paths, icons, breakpoints } from 'constants';
import { parseRichText } from 'utils/contentful-utils';
import { fetchContentful } from 'reduxState/contentful';
import { RECIPES, RECIPE_DETAIL_PAGE } from 'constants/contentful';

const featuredSeedlingsConfig = {
  slidesPerView: 1,
  navigation: true,
};

const discoverMoreRecipesConfig = {
  slidesPerView: 1.2,
  spaceBetween: 16,
  centeredSlides: true,
  loop: true,
};

class RecipeDetail extends Component {
  componentDidMount() {
    if (!this.props.fields) {
      this.props.fetchContentful(RECIPE_DETAIL_PAGE);
    }

    if (!this.props.content.length) {
      this.props.fetchContentful(RECIPES);
    }
  }

  componentDidUpdate() {
    const isGlobalLoading = this.props.isLoading;
    const recipe = this.getCurrentRecipe(this.props.content);
    const isLoading = _isEmpty(this.props.fields) || _isEmpty(recipe);

    if (!isLoading && isGlobalLoading) {
      this.props.setIsLoading(false);
      setTimeout(() => this.props.setIsLoading(false), 10);
    }

    if (!this.props.fields) {
      this.props.fetchContentful(RECIPE_DETAIL_PAGE);
    }

    if (!this.props.content.length) {
      this.props.fetchContentful(RECIPES);
    }
  }

  getCurrentRecipe = (content) => {
    return content.find((recipe) => recipe.fields.slug === this.props.slug);
  };

  getRecipesOfSamePlantTypes = (currentRecipe) => {
    return this.props.content.filter((recipe) => {
      const sharedPlantTypes = _intersectionBy(recipe.fields.plantType, currentRecipe.fields.plantType, 'sys.id');
      return !_isEmpty(sharedPlantTypes) && currentRecipe.sys.id !== recipe.sys.id;
    });
  };

  getOtherRecipes = (currentRecipe) => {
    return _difference(this.props.content, this.getRecipesOfSamePlantTypes(currentRecipe)).filter(
      (recipe) => !_isEqual(recipe, currentRecipe)
    );
  };

  getSpecDetails = (specName, recipe) => {
    let value = recipe.fields[_camelCase(specName)];
    if (typeof value === 'number') {
      value = `${value} mins`;
    }
    return value;
  };

  getFeaturedSeedlings = (recipe) => {
    return recipe?.fields?.plantType?.filter((plant) => plant.fields.weblistingEnabled);
  };

  renderFeaturedSeedlings = (plant) => (
    <div key={plant?.sys?.id} className='lg-recipe__plant-slide'>
      <Link to={`${paths.SEEDLINGS}/${get(plant, 'fields.slug')}`}>
        <Media asset={get(plant, 'fields.healthBenefitsImage.fields')} nativeWidth={600} modifiers='noTransform' />
        {plant?.fields?.friendlyName && <Text isBold>{plant?.fields?.friendlyName.toUpperCase()}</Text>}
      </Link>
    </div>
  );

  getDiscoverMoreRecipes = () => {
    const recipeOfSlug = this.getCurrentRecipe(this.props.content);
    if (!recipeOfSlug) return [];
    const otherRecipes = this.getOtherRecipes(recipeOfSlug);
    return _take(this.getRecipesOfSamePlantTypes(recipeOfSlug).concat(otherRecipes), 3);
  };

  renderDiscoverMore = (recipe) => (
    <Card key={recipe.fields.slug} modifiers='fullBleed'>
      <div className='lg-media-wrapper'>
        <Media asset={get(recipe.fields, 'mainImage.fields')} href={`${paths.RECIPES}/${recipe.fields.slug}`} nativeWidth={600} />
      </div>
      <Link to={`${paths.RECIPES}/${recipe.fields.slug}`}>
        <Text size={sizes.SMALL} color={colors.GREEN} isBold>
          {recipe.fields.prepTime ? `${recipe.fields.prepTime} MIN` : ''}
        </Text>
        <Title modifiers='tertiaryXSmall' content={recipe.fields.title}></Title>
      </Link>
    </Card>
  );

  renderRecipeImage = (image) => <Media key={image?.sys?.id} asset={image?.fields} nativeWidth={1000} modifiers='noTransform' />;

  getSpecIcon = (spec) => {
    switch (spec.fields.name.toLowerCase()) {
      case 'servings':
        return icons.SERVINGS;
      case 'difficulty':
        return icons.DIFFICULTY;
      case 'cook time':
      case 'prep time':
        return icons.TIMER;
      default:
        return;
    }
  };

  render() {
    const recipe = this.getCurrentRecipe(this.props.content);
    if (_isEmpty(this.props.fields) || _isEmpty(recipe)) return <div style={{ height: '100vh' }} />;
    const specTitles = get(this.props.fields, 'sections[0].fields.percentages');
    const discoverMoreSection = this.props.fields.sections.find((section) => section.sys.contentType.sys.id === 'sectionSimpleText');
    const author = get(recipe, 'fields.author.fields.name');
    const ogImage = get(recipe.fields, 'metaFogImage.fields.file.url') || get(recipe.fields, 'mainImage.fields.file.url');
    const twitterImage = get(recipe.fields, 'metaTwitterImage.fields.file.url') || get(recipe.fields, 'mainImage.fields.file.url');

    const featuredSeedlings = this.getFeaturedSeedlings(recipe);

    return (
      <div className='lg-page lg-recipe'>
        <RecipeDetailStyled>
          <Head
            title={`Lettuce Grow - ${recipe.fields.title}`}
            description={recipe.fields.introSentence}
            ogImage={ogImage}
            twitterImage={twitterImage}
            author={author}
            canonicalRoute={`${paths.RECIPES}/${recipe.fields.slug}`}
          />
          <RecipeBackButton>
            <Link to={paths.RECIPES}>
              <Text className='lg-breadcrumbs' color={colors.GREY} size={sizes.SMALLISH}>
                Back to Recipes
              </Text>
            </Link>
          </RecipeBackButton>
          <div className='lg-recipe__inner'>
            <RecipeInformation>
              <div className='lg-recipe__heading'>
                {recipe.fields.title && (
                  <Title
                    modifiers={{ SM: 'tertiarySmall', MD: 'secondarySmall' }}
                    className='lg-recipe__title'
                    size={this.props.width < breakpoints.MOBILE ? 'SM' : this.props.width < breakpoints.TABLET ? 'MD' : 'LG'}
                  >
                    {recipe.fields.title.toUpperCase()}
                  </Title>
                )}
                <div>
                  {!!author && (
                    <React.Fragment>
                      <Text className='lg-recipe__author' isBold color={colors.GREY} isInline size={sizes.SMALLISH}>
                        {author}
                      </Text>
                      <Text className='lg-recipe__bullet' size={sizes.XXLARGE} color={colors.GREEN} isInline>
                        &#183;
                      </Text>
                    </React.Fragment>
                  )}
                  <Text className='lg-recipe__date' isBold color={colors.GREY} isInline={!!author} size={sizes.SMALLISH}>
                    {formatDate(get(recipe.fields, 'publishDate'), true)}
                  </Text>
                </div>
                {!!specTitles && (
                  <div className='lg-recipe__specs'>
                    {specTitles.map((spec) => {
                      return (
                        <div key={spec.sys.id}>
                          <Text size={sizes.TINY} color={colors.GREY} isInline>
                            <span className={`icon icon-${this.getSpecIcon(spec)}`} />
                          </Text>
                          <Text size={sizes.SMALL} color={colors.GREY} isInline isBold>
                            {spec.fields.subText}
                          </Text>
                          <Text size={this.props.width < breakpoints.MOBILE ? sizes.SMALLISH : sizes.BASE} isBold color={colors.GREEN}>
                            {this.getSpecDetails(spec.fields.name, recipe)}
                          </Text>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <Media asset={get(recipe, 'fields.mainImage.fields')} modifiers='noTransform' />
              <Text size={sizes.BASE} className='lg-recipe__intro'>
                {recipe.fields.introSentence}
              </Text>
              <Text size={sizes.BASE} asHTML>
                {recipe.fields.bodyCopy}
              </Text>
            </RecipeInformation>
            {featuredSeedlings && (
              <FeaturedSeedlings>
                <FeaturedSeedlingsInner>
                  <Callout
                    title='FEATURED SEEDLINGS'
                    highlightPosition={positions.CENTER}
                    mediaAccent={discoverMoreSection.fields.mediaAccent}
                    className='lg-recipe__featured__heading'
                  />
                  <Swiper swiperProps={featuredSeedlingsConfig} items={featuredSeedlings} renderSlideCard={this.renderFeaturedSeedlings} />
                </FeaturedSeedlingsInner>
              </FeaturedSeedlings>
            )}
            {recipe?.fields?.ingredients && (
              <>
                <Callout className='lg-recipe__ingredients__heading' title='INGREDIENTS' highlightPosition={positions.CENTER} />

                <div className='lg-recipe__ingredients-wrapper'>
                  <div className='lg-recipe__ingredients'>
                    {recipe?.fields?.ingredients?.map((ingredient, idx) => {
                      let ingredientName = (
                        <Text size={sizes.BASE} isInline>
                          {ingredient?.fields?.name}
                        </Text>
                      );
                      const recipeReferenceSlug = get(ingredient.fields, 'recipeReference.fields.slug'); //to link to ingredients that are other recipes
                      return (
                        <React.Fragment key={idx}>
                          <Text size={sizes.BASE} isInline color={colors.GREY}>
                            {ingredient?.fields?.quantity}
                          </Text>
                          {recipeReferenceSlug ? (
                            <a
                              className='lg-recipe__ingredients-link'
                              href={`${paths.RECIPES}/${recipeReferenceSlug}`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {ingredientName}
                            </a>
                          ) : (
                            ingredientName
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <Instructions>
              <InstructionsInner>
                <Callout className='lg-recipe__instructions__heading' title='INSTRUCTIONS' highlightPosition={positions.CENTER} />
                <div className='lg-recipe__instructions'>{parseRichText(recipe.fields.instructions)}</div>
                <CarouselWrapper>
                  <Swiper items={get(recipe, 'fields.imageGallery', [])} renderSlideCard={this.renderRecipeImage} />
                </CarouselWrapper>
              </InstructionsInner>
            </Instructions>
          </div>
          {discoverMoreSection && (
            <DiscoverMoreSection>
              <Callout
                className='lg-recipe__discover-more__heading'
                title={discoverMoreSection.fields.title}
                mediaAccent={discoverMoreSection.fields.mediaAccent}
                highlightPosition={positions.CENTER}
              />
              {this.props.width < breakpoints.TABLET ? (
                <Swiper
                  items={this.getDiscoverMoreRecipes()}
                  renderSlideCard={this.renderDiscoverMore}
                  swiperProps={discoverMoreRecipesConfig}
                />
              ) : (
                <DiscoverMore>{this.getDiscoverMoreRecipes().map(this.renderDiscoverMore)}</DiscoverMore>
              )}
            </DiscoverMoreSection>
          )}
        </RecipeDetailStyled>
      </div>
    );
  }
}

RecipeDetail.defaultProps = {
  content: [],
};

RecipeDetail.propTypes = {
  content: PropTypes.array,
  slug: PropTypes.string.isRequired,
  fetchContentful: PropTypes.func,
  isLoading: PropTypes.bool,
  fields: PropTypes.array,
  setIsLoading: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = ({ appSettings, contentful }) => ({
  isLoading: appSettings.isLoading,
  fields: contentful.recipeDetailPage?.[0]?.fields,
  content: contentful.recipes,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setIsLoading, fetchContentful }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WidthProvider(RecipeDetail));
